import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../components/RawHTML';
import Container from '../../../../../components/Container';

const html = `

<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">AnnotationViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[AnnotationViewController initWithToolManager:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> e_ptflatten_high_quality</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> e_ptrs_failure</div>
<div class="difference"><span class="status added">Added</span> e_ptrs_success</div>
<div class="difference"><span class="status added">Added</span> e_ptrs_security_error</div>
<div class="difference"><span class="status added">Added</span> e_ptrs_cancel</div>
<div class="difference"><span class="status added">Added</span> e_ptrs_package_error</div>
<div class="difference"><span class="status added">Added</span> e_ptrs_previous_crash</div>
<div class="difference"><span class="status added">Added</span> e_ptrs_not_found</div>
<div class="difference"><span class="status added">Added</span> e_ptrs_fetch</div>
<div class="difference"><span class="status added">Added</span> e_ptrs_render</div>
<div class="difference"><span class="status added">Added</span> e_ptrs_postponed</div>
<div class="difference"><span class="status added">Added</span> PTResultState</div>
<div class="difference"><span class="status added">Added</span> e_pthigh_quality</div>
<div class="difference"><span class="status added">Added</span> e_ptstructural_algorithm_2</div>
<div class="difference"><span class="status added">Added</span> e_ptnormal_annot_bitmap</div>
<div class="difference"><span class="status added">Added</span> e_ptmult_blend_annot_bitmap</div>
<div class="difference"><span class="status added">Added</span> e_ptnormal_annot_vector</div>
<div class="difference"><span class="status added">Added</span> e_ptmult_blend_annot_vector</div>
<div class="difference"><span class="status added">Added</span> e_ptresize_annot_bitmap</div>
<div class="difference"><span class="status added">Added</span> e_ptremove_annot_bitmap</div>
<div class="difference"><span class="status added">Added</span> e_ptclear_all_annot_bitmaps</div>
<div class="difference"><span class="status added">Added</span> e_ptannot_renders_complete</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBitmapFlags</div>
<div class="difference"><span class="status added">Added</span> e_ptno_vector_annots</div>
<div class="difference"><span class="status added">Added</span> e_ptpdftron_vector_annots</div>
<div class="difference"><span class="status added">Added</span> PTAnnotVectorMode</div>
<div class="difference"><span class="status added">Added</span> -[PTHTTPRequestOptions RestrictDownloadUsage:]</div>
<div class="difference"><span class="status added">Added</span> PTConversionOptions</div>
<div class="difference"><span class="status added">Added</span> -[PTConversionOptions getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTConversionOptions initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTConversionOptions setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTConversionOptions dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTConversionOptions init]</div>
<div class="difference"><span class="status added">Added</span> PTOfficeToPDFOptions</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions GetLayoutResourcesPluginPath]</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions SetLayoutResourcesPluginPath:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions GetResourceDocPath]</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions SetResourceDocPath:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions GetSmartSubstitutionPluginPath]</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions SetSmartSubstitutionPluginPath:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions GetExcelDefaultCellBorderWidth]</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions SetExcelDefaultCellBorderWidth:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions init]</div>
<div class="difference"><span class="status added">Added</span> +[PTConvert OfficeToPDF:in_filename:options:]</div>
<div class="difference"><span class="status added">Added</span> +[PTConvert StreamingPDFConversionWithDoc:in_filename:options:]</div>
<div class="difference"><span class="status added">Added</span> +[PTConvert StreamingPDFConversion:options:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFView SetWrapperAnnotBitmapProc:mode:]</div>
<div class="difference"><span class="status added">Added</span> PTJobRequestProcHandler</div>
<div class="difference"><span class="status added">Added</span> PTReflowProcessor</div>
<div class="difference"><span class="status added">Added</span> -[PTReflowProcessor getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTReflowProcessor initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTReflowProcessor setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTReflowProcessor dealloc]</div>
<div class="difference"><span class="status added">Added</span> +[PTReflowProcessor Initialize]</div>
<div class="difference"><span class="status added">Added</span> +[PTReflowProcessor IsInitialized]</div>
<div class="difference"><span class="status added">Added</span> +[PTReflowProcessor GetReflow:proc:custom_data:]</div>
<div class="difference"><span class="status added">Added</span> +[PTReflowProcessor CancelAllRequests]</div>
<div class="difference"><span class="status added">Added</span> +[PTReflowProcessor CancelRequest:]</div>
<div class="difference"><span class="status added">Added</span> +[PTReflowProcessor ClearCache]</div>
<div class="difference"><span class="status added">Added</span> -[PTReflowProcessor init]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTWordToPDFOptions</div>
<table>
<tr><th></th><th>Superclass</th></tr>
<tr><th>From</th><td>NSObject</td></tr>
<tr><th>To</th><td>PTConversionOptions</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> -[PTPDFView OpenURLAsync:cache_file:password:options:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)OpenURLAsync:(NSString *)url cache_file:(NSString *)cache_file password:(NSString *)password options:(SWIGTYPE_p_pdftron__PDF__PDFView__HTTPRequestOptions *)options</td></tr>
<tr><th>To</th><td class="declaration">- (void)OpenURLAsync:(NSString *)url cache_file:(NSString *)cache_file password:(NSString *)password options:(PTHTTPRequestOptions *)options</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTTool pdfViewCtrl:generatedAppearance:forAnnot:onPage:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTPDFViewCtrl.delegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, assign) id&lt;PTPDFViewCtrlDelegate&gt; delegate</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, weak) id&lt;PTPDFViewCtrlDelegate&gt; delegate</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTPDFViewCtrl.toolDelegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, assign) id&lt;PTTool&gt; toolDelegate</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, weak) id&lt;PTTool&gt; toolDelegate</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/Private2.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> SWIGTYPE_UInt64</div>
<div class="difference"><span class="status removed">Removed</span> -[SWIGTYPE_UInt64 getCptr]</div>
<div class="difference"><span class="status removed">Removed</span> -[SWIGTYPE_UInt64 initWithCptr:]</div>
<div class="difference"><span class="status removed">Removed</span> -[SWIGTYPE_UInt64 setSwigCMemOwn:]</div>
<div class="difference"><span class="status removed">Removed</span> SWIGTYPE_p_void</div>
<div class="difference"><span class="status removed">Removed</span> -[SWIGTYPE_p_void getCptr]</div>
<div class="difference"><span class="status removed">Removed</span> -[SWIGTYPE_p_void initWithCptr:]</div>
<div class="difference"><span class="status removed">Removed</span> -[SWIGTYPE_p_void setSwigCMemOwn:]</div>
<div class="difference"><span class="status removed">Removed</span> SWIGTYPE_p_pdftron__PDF__PDFView__HTTPRequestOptions</div>
<div class="difference"><span class="status removed">Removed</span> -[SWIGTYPE_p_pdftron__PDF__PDFView__HTTPRequestOptions getCptr]</div>
<div class="difference"><span class="status removed">Removed</span> -[SWIGTYPE_p_pdftron__PDF__PDFView__HTTPRequestOptions initWithCptr:]</div>
<div class="difference"><span class="status removed">Removed</span> -[SWIGTYPE_p_pdftron__PDF__PDFView__HTTPRequestOptions setSwigCMemOwn:]</div>
<div class="difference"><span class="status removed">Removed</span> SWIGTYPE_Int64</div>
<div class="difference"><span class="status removed">Removed</span> -[SWIGTYPE_Int64 getCptr]</div>
<div class="difference"><span class="status removed">Removed</span> -[SWIGTYPE_Int64 initWithCptr:]</div>
<div class="difference"><span class="status removed">Removed</span> -[SWIGTYPE_Int64 setSwigCMemOwn:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTCoordinatedDocument.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTCoordinatedDocument</div>
<div class="difference"><span class="status added">Added</span> PTCoordinatedDocument.pdfDoc</div>
<div class="difference"><span class="status added">Added</span> PTCoordinatedDocument.delegate</div>
<div class="difference"><span class="status added">Added</span> PTCoordinatedDocumentDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTCoordinatedDocumentDelegate coordinatedDocumentDidChange:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCoordinatedDocumentDelegate coordinatedDocument:presentedItemDidMoveToURL:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTDocumentOpening.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTDocumentOpening openDocumentWithURL:password:error:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController setDocumentWrapper:error:]</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.documentWrapper</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewControllerSaveDocumentInterval</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewControllerDelegate documentViewControllerDidBecomeInvalid:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController setCoordinatedDocument:completionHandler:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.coordinatedDocument</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.automaticallySavesDocument</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.automaticDocumentSavingInterval</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController restartAutomaticDocumentSavingTimer]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController restartAutomaticDocumentSavingTimerWithInterval:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController stopAutomaticDocumentSavingTimer]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTDocumentWrapper.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PTDocumentWrapper</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentWrapper.pdfDoc</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTReflowViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTReflowViewControllerDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTReflowViewControllerDelegate reflowController:didChangeToPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTReflowViewControllerDelegate reflowController:handleTap:]</div>
<div class="difference"><span class="status added">Added</span> -[PTReflowViewControllerDelegate reflowControllerDidCancel:]</div>
<div class="difference"><span class="status added">Added</span> PTReflowViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTReflowViewController initWithPDFViewCtrl:]</div>
<div class="difference"><span class="status added">Added</span> PTReflowViewController.delegate</div>
<div class="difference"><span class="status added">Added</span> PTReflowViewController.pageNumber</div>
<div class="difference"><span class="status added">Added</span> -[PTReflowViewController setPageNumber:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTReflowViewController.scale</div>
<div class="difference"><span class="status added">Added</span> PTReflowViewController.turnPageOnTap</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTTabbedDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController addTabWithCoordinatedDocument:selected:completionHandler:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController insertTabWithCoordinatedDocument:atIndex:selected:completionHandler:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController removeTabWithCoordinatedDocument:]</div>
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewController.automaticallySavesDocuments</div>
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewController.automaticDocumentSavingInterval</div>
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewController.automaticallyHidesControls</div>
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewController.automaticControlHidingDelay</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">SelectionRectContainerView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[SelectionRectContainerView initWithPDFViewCtrl:forAnnot:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[SelectionRectContainerView initWithPDFViewCtrl:forAnnot:withTool:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">SelectionRectView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[SelectionRectView initWithFrame:forAnnot:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[SelectionRectView initWithFrame:forAnnot:withTool:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">ToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> ToolManager.pageIndicatorEnabled</div>
<div class="difference"><span class="status added">Added</span> ToolManager.readonly</div>
<div class="difference"><span class="status added">Added</span> ToolManagerAnnotationAddedNotification</div>
<div class="difference"><span class="status added">Added</span> ToolManagerAnnotationModifiedNotification</div>
<div class="difference"><span class="status added">Added</span> ToolManagerAnnotationRemovedNotification</div>
<div class="difference"><span class="status added">Added</span> ToolManagerPermissionsDidChangeNotification</div>
<div class="difference"><span class="status added">Added</span> ToolManagerAnnotationUserInfoKey</div>
<div class="difference"><span class="status added">Added</span> ToolManagerPageNumberUserInfoKey</div>
<div class="difference"><span class="status added">Added</span> ToolManagerAnnotationNamesUserInfoKey</div>
<div class="difference"><span class="status added">Added</span> ToolManagerInteractionToolNamesUserInfoKey</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">ToolManagerConfiguration.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> ToolManagerConfiguration.pageIndicatorEnabled</div>
<div class="difference"><span class="status added">Added</span> ToolManagerConfigurationBuilder.pageIndicatorEnabled</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">ToolsMacros.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> #def PT_PROP_KEY_PATH</div>
</div>

</div>
</body>
</html>



`


export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}

