import React from 'react';

export default function RawHTML ({ html }) {

  html = html.replace('<html>', '');
  html = html.replace('</html>', '');
  html = html.replace(/<head>[\s\S]*<\/head>/g, '');
  html = html.replace('<body>', '');
  html = html.replace('</body>', '');

  return (
    <div dangerouslySetInnerHTML={{
      __html: html
    }} />
  )
}